import AppLoader from './components/app-loader';
import HomePage from './components/home';

/**
 * Root app, initialises AppLoader and displays the HomePage
 * @returns
 */
function App() {

  return (
    <AppLoader>
      <HomePage />
    </AppLoader>
  );

}

export default App;
