import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { FirebaseOptions } from '@firebase/app-types';
import { isLocalhost } from './utils';
import axios from 'axios';

export default init;

/**
 * Initialise Firebase
 * @returns
 */
async function init(): Promise<firebase.app.App> {

    const config = await getFirebaseConfig();
    const app = firebase.initializeApp(config);

    // Use emulators when running locally
    if (isLocalhost() === true) {
        firebase.firestore().useEmulator('localhost', 8080);
        firebase.auth().useEmulator('http://localhost:9099');
    }

    return app;

}

/**
 * Get firebase config from server, fallback to default
 * @returns
 */
async function getFirebaseConfig(): Promise<FirebaseOptions> {

    const defaultConfig = {
        apiKey: 'AIzaSyBmKSQlzI0BG0Y6xTsVtHdV-lzvD0kf7iE',
        authDomain: 'fuocos-pizza-dev.firebaseapp.com',
        databaseURL: 'https://fuocos-pizza-dev.firebaseio.com',
        projectId: 'fuocos-pizza-dev',
        storageBucket: 'fuocos-pizza-dev.appspot.com',
        messagingSenderId: '1033200475107',
        appId: '1:1033200475107:web:2e196f1d7d78cab8af5ed6',
        measurementId: 'G-7CZN43KSH6'
    };

    try {
        const { data } = await axios.get('/__/firebase/init.json');
        return data;
    } catch (error) {
        return defaultConfig;
    }

}
