import React, { useState, ReactNode, useEffect, createContext } from 'react'
import firebaseInit from '../config/firebase';
import wakeLockInit from '../config/wakelock';


type TAppLoaderArgs = {
    children: ReactNode;
    [key: string]: unknown;
}

type TAppLoaderContext = {
    firebase: firebase.default.app.App | null;
}


export const AppLoaderContext = createContext<TAppLoaderContext>({ firebase: null });

/**
 * Load all app wide dependencies
 * @returns
 */
function AppLoader(props: TAppLoaderArgs) {

    const [firebaseLoaded, setFirebaseLoaded] = useState<firebase.default.app.App>();
    const [wakeLockLoaded, setWakeLockLoaded] = useState<boolean>(false);

    useEffect(() => {
        firebaseInit().then(app => setFirebaseLoaded(app));
    }, [])

    useEffect(() => {
        wakeLockInit().then(() => setWakeLockLoaded(true));
    }, [])

    if (firebaseLoaded && wakeLockLoaded) return (
        <AppLoaderContext.Provider value={{ firebase: firebaseLoaded }}>
            {props.children}
        </AppLoaderContext.Provider>
    );

    // Wait until system is initalised
    return (<h1>Loading...</h1>);

}

export default AppLoader
