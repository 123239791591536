import { useEffect, useState } from 'react';

const screen = window.screen;

type TOrientation = 'landscape' | 'portrait';

const useOrientation = (): { orientation: TOrientation } => {

    /** Switch between portrait & landscape splashscreens */
    const getOrientation = (): TOrientation => {
        return screen.orientation.type.includes('landscape') ? 'landscape' : 'portrait'
    };

    const [orientation, setOrientation] = useState(getOrientation());

    useEffect(() => {

        screen.orientation.onchange = (arg) => {
            setOrientation(getOrientation());
        };

        return () => {
            screen.orientation.onchange = null
        };

    }, []);

    return { orientation };

};

export default useOrientation;
