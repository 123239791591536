import React, { FormEvent, useContext, useEffect, useState, MouseEvent } from 'react'
import { AppLoaderContext } from './app-loader';
import fb from 'firebase/app';
import 'firebase/auth';
import useManagedData from '../hooks/managed-data';

function Login() {

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setError] = useState<string[]>([]);
    const { firebase } = useContext(AppLoaderContext);
    const managedData = useManagedData();

    const signInWithEmailAndPassword = async (email: string, password: string) => {
        try {
            await firebase?.auth().signInWithEmailAndPassword(email, password);
        } catch (error: any) {
            setError((current) => [error.message, ...errorMessage]);
        }
    };

    const handleGoogleLogin = async (e: MouseEvent) => {
        try {
            e.preventDefault();
            await firebase?.auth().signInWithPopup(new fb.auth.GoogleAuthProvider())
        } catch (error: any) {
            setError((current) => [error.message, ...errorMessage]);
        }
    }

    const handleLogin = async (e: FormEvent) => {
        try {
            e.preventDefault();
            await signInWithEmailAndPassword(email, password);
        } catch (error: any) {
            setError((current) => [error.message, ...errorMessage]);
        }
    };

    // Handle automated kiosk login
    useEffect(() => {

        if (!managedData) return;

        const { user: email, password } = managedData;

        if (email && password && firebase) {
            firebase.auth().signInWithEmailAndPassword(email, password);
        }

    }, [managedData, firebase]);

    return (
        <React.Fragment>

            {/* Login with Google */}
            <button onClick={handleGoogleLogin}>Sign in with Google</button>

            {/* Login Manually */}
            <form onSubmit={handleLogin}>
                <input required type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
                <input required type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                <button type="submit" disabled={email === '' || password === ''}>Login</button>
            </form>

            {/* Display errors */}
            {errorMessage.length > 0 && <div>{JSON.stringify(errorMessage, null, 4)}</div>}

            {/* Display Managed Data */}
            {/* {managedData && <pre>{JSON.stringify(managedData, null, 4)}</pre>} */}

        </React.Fragment>
    )



}

export default Login
