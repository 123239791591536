import React, { useRef, CSSProperties, useState, useEffect } from 'react'
import { castArray } from 'lodash';


type TVideoArgs = {
    url: string | string[];
};


function Video({ url }: TVideoArgs): JSX.Element {

    // https://storage.googleapis.com/fuocos-pizza-dev.appspot.com/phoenix-videos/November%202021%20-%20Wide%201%20Min.mp4

    const sourceElement = useRef<HTMLSourceElement>(null);
    const videoElement = useRef<HTMLVideoElement>(null);
    const [activeVideoIndex, setActiveVideoIndex] = useState<number>(0);
    const [activeVideoUrl, setActiveVideoUrl] = useState<string>();
    const videoStyle: CSSProperties = { width: '100vw', height: '100vh', backgroundColor: 'black' };
    const sourceUrls = castArray(url);

    useEffect(() => {
        setActiveVideoUrl(sourceUrls[activeVideoIndex]);
    }, [activeVideoIndex, sourceUrls])

    useEffect(() => {

        if (videoElement.current === null) return;

        videoElement.current.addEventListener('ended', (e) => {
            setActiveVideoIndex((activeVideoIndex + 1) % url.length);
        });

    }, [activeVideoIndex, activeVideoUrl, url.length]);

    return (
        <React.Fragment>
            <video key={activeVideoUrl} ref={videoElement} style={videoStyle} controls={false} muted={false} autoPlay={true}>
                <source ref={sourceElement} src={activeVideoUrl} />
            </video>
        </React.Fragment>
    );

}

export default Video
