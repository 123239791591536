import { useContext } from 'react'
import { AppLoaderContext } from './app-loader';
import Closed from './closed';
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { COLLECTION_NAMES } from '@hcstechnologies/fuocos-interfaces/dist/types';
import { TPhoenixSettings } from '@hcstechnologies/fuocos-interfaces/src/types';
import Menu from './menu';
import Video from './video';

/**
 * Switch between menu & closed
 * @param param0
 * @returns
 */
function DisplaySwitcher() {

    const { firebase } = useContext(AppLoaderContext);
    const settingsRef = firebase!.firestore().doc(`${COLLECTION_NAMES.SETTINGS}/phoenix`);
    const [settingsData] = useDocumentData<TPhoenixSettings>(settingsRef);

    // If this is a video, display the media player
    if (settingsData?.currentMenu === 'video' && settingsData.url) {
        return <Video url={settingsData.url} />;
    }

    if (settingsData?.currentMenu) return <Menu menuId={settingsData.currentMenu} />

    return <Closed />

}

export default DisplaySwitcher
