import React, { useContext } from 'react'
import { AppLoaderContext } from './app-loader';
import { useAuthState } from 'react-firebase-hooks/auth';
import useManagedData from '../hooks/managed-data';
import Login from './login';
import DisplaySwitcher from './display-switcher';


function HomePage() {

    const { firebase } = useContext(AppLoaderContext);
    const [authContext, authLoading] = useAuthState(firebase!.auth());
    const managedData = useManagedData();

    // Display loading if managedData is not loaded
    if (managedData === undefined || authLoading) return <div>Loading</div>;

    // Display menu if authenticated
    if (authContext) return <DisplaySwitcher />;

    // Display login if managed data is null
    return <Login />

}

export default HomePage
