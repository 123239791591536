import { useEffect, useState } from 'react';
import logoPortrait from '../media/splash-portrait.png';
import logoLandscape from '../media/splash-landscape.png';
import useOrientation from '../hooks/screen-orientation';

function Closed() {

    const { orientation } = useOrientation();
    const [logo, setlogo] = useState<string>(logoLandscape);

    useEffect(() => {
        setlogo(orientation === 'landscape' ? logoLandscape : logoPortrait);
    }, [orientation])

    return <img style={{ width: '100vw', height: '100vh' }} src={logo} alt="Fuocos Splash" />;

}

export default Closed
