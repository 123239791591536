import React, { useContext, useMemo } from 'react'
import { COLLECTION_NAMES } from '@hcstechnologies/fuocos-interfaces/dist/types';
import {
    TPhoenixMenu, TPhoenixMenuCategory,
    TPhoenixAllergens, TPhoenixSettings, TPheonixExtraToppings
} from '@hcstechnologies/fuocos-interfaces/src/types';
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { AppLoaderContext } from './app-loader';
import logo from '../media/menu-logo.png';
import Grid from '@mui/material/Grid';
import fish from '../media/fish.png';
import nut from '../media/nut.png';
import milk from '../media/milk.png';
import gluten from '../media/gluten.png';
import soya from '../media/soya.png';
import mustard from '../media/mustard.png';
import celery from '../media/celery.png';
import eggs from '../media/eggs.png';
import treenut from '../media/treenut.png';

import filler from '../media/flame.jpg';
import useOrientation from '../hooks/screen-orientation';
import { chunk, ceil } from 'lodash';
import bg from '../assets/bg.jpg';
import { Stack } from '@mui/material';
import { AllergenChip } from './allergen-chip';

type TMenuArgs = { menuId: string; }
type TExtraToppingsArgs = TSettings & { extraToppings: TPheonixExtraToppings[] };
type TFooterArgs = TSettings;
type TSettings = { settings: TPhoenixSettings }
type THeaderArgs = TSettings & { menuName: string; };
type TMenuCategoryArgs = TSettings & { category: TPhoenixMenuCategory; displayMenuItems?: boolean };
type TExtraToppingArgs = TSettings & { topping: TPheonixExtraToppings }

type TMenuType = 'header' | 'product' | 'extrasHeader' | 'extra';

type TMenuItems = Exclude<TPhoenixMenuCategory['items'], 'items'>;
type TMenuItem = TMenuItems[0];

type TMenuItemArgs = TSettings & { item: TMenuItem };

type TFlatMenu = {
    type: TMenuType;
    value: TPhoenixMenuCategory | TPheonixExtraToppings | TMenuItem | null;
};

type Tlayout = TSettings & { menu: TPhoenixMenu };

type TAllergenIconArgs = TSettings & { type: TPhoenixAllergens };



function Menu({ menuId }: TMenuArgs) {

    const { firebase } = useContext(AppLoaderContext);
    const menuRef = firebase!.firestore().doc(`${COLLECTION_NAMES.PHOENIX_MENU}/${menuId}`);
    const [menuData, loadingMenu] = useDocumentData<TPhoenixMenu>(menuRef);

    const settingsRef = firebase!.firestore().doc(`${COLLECTION_NAMES.SETTINGS}/phoenix`);
    const [settingsData, loadingSettings] = useDocumentData<TPhoenixSettings>(settingsRef);

    const { orientation } = useOrientation();

    console.log(`Menu: ${orientation}`);

    if (loadingMenu || loadingSettings) return <h1>Loading...</h1>;
    if (menuData === undefined) return <h1>Menu {menuId} not found!</h1>;
    if (settingsData === undefined) return <h1>Settings not found!</h1>;

    return (
        <React.Fragment>
            {orientation === 'portrait' && <PortraitLayout settings={settingsData} menu={menuData} />}
            {orientation === 'landscape' && <LandscapeLayout settings={settingsData} menu={menuData} />}
        </React.Fragment>
    );


}

function PortraitLayout({ settings, menu }: Tlayout): JSX.Element {

    return (

        <Grid container direction="column" className="menu" style={{ minHeight: '100vh', overflow: 'scroll' }}>

            {/* Header */}
            <Grid item>
                <Header menuName={menu.name} settings={settings} />
            </Grid>

            {/* Menu Content */}
            <Grid item width={'100%'}>
                {menu.category.map(category => <MenuCategory key={category.name} category={category} settings={settings} />)}
            </Grid>

            {/* Toppings */}
            {menu.extraToppings.length > 0 &&
                <Grid item width={'100%'}>
                    <ExtraToppings extraToppings={menu.extraToppings} settings={settings} />
                </Grid>
            }

            <Grid item xs sx={{
                backgroundImage: `url(${filler})`,
                backgroundSize: 'cover',
                borderTop: '4px solid black',
            }} />

            {/* Footer */}
            <Grid item>
                <Footer settings={settings} />
            </Grid>

        </Grid>

    );

}


function LandscapeLayout({ settings, menu }: Tlayout): JSX.Element {

    /**
     * Transform nested menu into a flat array
     * This is required to ensure an even layout
     */
    const transformedMenu = useMemo(() => {

        const COLUMNS = 3;

        const products = menu.category.reduce((memo: Array<TFlatMenu>, category) => {

            memo.push({
                type: 'header',
                value: category,
            });

            category.items.forEach(item => memo.push({ type: 'product', value: item }));

            return memo;

        }, []);

        if (menu.extraToppings.length > 0) products.push({ type: 'extrasHeader', value: null })

        menu.extraToppings.forEach(extra => products.push({ type: 'extra', value: extra }));

        const chunks = chunk(products, ceil(products.length / (menu.columns ?? COLUMNS)));

        return chunks;

    }, [menu]);


    return (

        <Grid container
            direction="column" className="menu" justifyContent={'center'}
            width={'100vw'} height={'100vh'} overflow={'auto'}
            sx={{ background: `url(${bg})` }}>

            {/* Header */}
            <Grid item>
                <Header menuName={menu.name} settings={settings} />
            </Grid>

            {/* Menu */}
            <Grid item xs container direction="row" justifyContent={'center'} px={2} pb={2} columnGap={2}>

                {transformedMenu.map((column, index) => {
                    return (

                        // Column
                        <Grid
                            key={index}
                            item xs
                            container
                            direction="column"
                            className="menuCategory"
                            border={'4px solid black'}
                            maxWidth={'40vw'}>

                            {column.map((col, index) => {

                                return (
                                    <Grid key={index} item>

                                        {col.type === 'header' &&
                                            <MenuCategory
                                                settings={settings}
                                                category={col.value as TPhoenixMenuCategory}
                                                displayMenuItems={false}
                                            />
                                        }

                                        {col.type === 'product' &&
                                            <MenuItem
                                                settings={settings}
                                                item={col.value as TMenuItem}
                                            />
                                        }

                                        {col.type === 'extrasHeader' &&
                                            <div className="menuCategory alt">
                                                <h1 style={{ fontSize: settings.lanscapeStyles?.categoryFontSize }}>
                                                    Extra Toppings
                                                </h1>
                                            </div>
                                        }

                                        {col.type === 'extra' &&
                                            <ExtraTopping
                                                settings={settings}
                                                topping={col.value as TPheonixExtraToppings} />
                                        }
                                    </Grid>
                                );

                            })}

                        </Grid>
                    );
                })}

            </Grid>

            {/* Footer */}
            {/* <Grid item>
                <Footer settings={settings} />
            </Grid> */}

        </Grid>

    );


}

function Header({ menuName, settings }: THeaderArgs) {

    const { orientation } = useOrientation();

    const width = (orientation === 'landscape' ?
        settings.lanscapeStyles?.logoWidth :
        settings.styles?.logoWidth) ?? '10vw';

    return (
        <div className="header">
            <div style={{ display: 'none' }}>{menuName}</div>
            <img style={{ width, height: 'auto' }} src={logo} alt="Fuoco's Pizza" />
        </div>
    );

}

function Footer({ settings }: TFooterArgs) {

    return (

        <Grid container direction="row" width={'100%'}>

            <Grid item xs />

            <Grid
                item
                bgcolor={'white'}
                borderRadius={2}
                m={2}
                borderColor={'black'}>

                <Stack direction={'row'} p={2} gap={2}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        ALLERGENS
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <AllergenIcon type="fish" settings={settings} /> Fish
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <AllergenIcon type="gluten" settings={settings} /> Gluten
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <AllergenIcon type="milk" settings={settings} /> Milk
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <AllergenIcon type="nut" settings={settings} /> Nut
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <AllergenIcon type="soya" settings={settings} /> Soya
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <AllergenIcon type="mustard" settings={settings} /> Mustard
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <AllergenIcon type="eggs" settings={settings} /> Egg
                    </div>

                </Stack>


            </Grid>


        </Grid>

    );
}

function MenuCategory({ category, settings, displayMenuItems = true }: TMenuCategoryArgs) {

    const { orientation } = useOrientation();
    const styles = orientation === 'landscape' ? settings.lanscapeStyles : settings.styles;

    return (
        <div className="menuCategory">
            <h1 style={{
                fontSize: styles?.categoryFontSize,
                backgroundColor: category.headerBgColour,
                color: category.headerColour,
            }}>
                {category.name}
            </h1>
            {
                displayMenuItems &&
                category.items.map(item => <MenuItem key={item.name} item={item} settings={settings} />)
            }
        </div>
    );

}

/**
 * Display Menu item, adds styling based upon landscape or portrait
 * @param param0
 * @returns
 */
function MenuItem({ item, settings }: TMenuItemArgs) {

    const { orientation } = useOrientation();
    const styles = orientation === 'landscape' ? settings.lanscapeStyles : settings.styles;

    return (

        <Grid item
            container direction="row"
            height={'100%'}
            px={2}
            borderTop={4}
            borderColor={'black'}>

            <Grid container direction="column" item xs alignContent="center" justifyContent={'center'}>

                <Grid container direction="row" alignItems="center">

                    <Grid item xs fontSize={styles?.nameFontSize ?? '2vw'} fontWeight={700}>
                        {item.name}
                    </Grid>

                    {/* Allergens */}
                    {item.allergens?.length > 0 &&
                        <Grid item p={1}>
                            <Stack direction={'row'} gap={0.25}>
                                {item.allergens.map(allergen =>
                                    <AllergenChip
                                        key={allergen}
                                        type={allergen}
                                        settings={settings} />
                                )}
                            </Stack>
                        </Grid>
                    }
                </Grid>
                <Grid item
                    fontSize={styles?.detailsFontSize ?? '1vw'}
                    fontFamily={'OverpassMono-Regular'}>
                    {item.description}
                </Grid>
            </Grid>

            <Grid item xs={2}
                container direction="row" justifyContent="end" alignContent="center"
                borderLeft={4}
                borderColor={'black'}
                fontSize={styles?.priceFontSize ?? '2vw'}>
                {formatNumber(item.price)}
            </Grid>

        </Grid>
    );

}

function ExtraToppings({ extraToppings, settings }: TExtraToppingsArgs) {

    const { orientation } = useOrientation();
    const styles = orientation === 'landscape' ? settings.lanscapeStyles : settings.styles;

    return (
        <div className="menuCategory alt">
            <h1 style={{ fontSize: styles?.categoryFontSize }}>
                Extra Toppings
            </h1>
            {extraToppings.map(extras => <ExtraTopping key={extras.name} topping={extras} settings={settings} />)}
        </div>
    );
}

function ExtraTopping({ settings, topping }: TExtraToppingArgs) {

    const { orientation } = useOrientation();
    const styles = orientation === 'landscape' ? settings.lanscapeStyles : settings.styles;

    return (

        <Grid item
            container direction="row"
            height={'100%'}
            px={2}
            borderTop={4}
            borderColor={'black'}>

            {/* Name */}
            <Grid item xs container direction="column">
                <Grid container direction="row" alignItems="center">
                    <Grid item xs fontSize={styles?.nameFontSize ?? '1vw'}>
                        {topping.name}
                    </Grid>
                </Grid>
                <Grid item xs
                    fontFamily={'OverpassMono-Regular'} fontSize={styles?.detailsFontSize ?? '1vw'}>
                    {topping.items.map(item => item.trim()).join(', ')}
                </Grid>
            </Grid>

            {/* Price  */}
            <Grid item xs={2}
                container direction="row" justifyContent="end" alignContent="center"
                borderLeft={4}
                borderColor={'black'}
                fontSize={styles?.priceFontSize ?? '2vw'}>
                {formatNumber(topping.price)}
            </Grid>

        </Grid>

    );

}

function formatNumber(number: number): string {
    return number.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

function AllergenIcon({ type, settings }: TAllergenIconArgs) {

    const { orientation } = useOrientation();
    const styles = orientation === 'landscape' ? settings.lanscapeStyles : settings.styles;

    const images: Record<TPhoenixAllergens, string> = { milk, gluten, nut, fish, soya, mustard, celery, eggs, treenut };
    const width = styles?.allergenIconSize ?? '1vw';
    return <img src={images[type]} alt={type} style={{ width }} />
}

export default Menu
