import React from 'react'

import fish from '../media/fish.png';
import nut from '../media/nut.png';
import milk from '../media/milk.png';
import gluten from '../media/gluten.png';
import soya from '../media/soya.png';
import mustard from '../media/mustard.png';
import celery from '../media/celery.png';
import eggs from '../media/eggs.png';
import treenut from '../media/treenut.png';
import { TPhoenixAllergens, TPhoenixSettings } from '@hcstechnologies/fuocos-interfaces/src/types';
import { Box } from '@mui/material';
import useOrientation from '../hooks/screen-orientation';


export type TAllergenChip = {
    type: TPhoenixAllergens;
    settings: TPhoenixSettings;
}

export function AllergenChip({ type, settings }: TAllergenChip) {

    const images: Record<TPhoenixAllergens, string> = {
        milk, gluten, nut, fish, soya,
        mustard, celery, eggs, treenut
    };

    const { orientation } = useOrientation();
    const styles = orientation === 'landscape' ? settings.lanscapeStyles : settings.styles;

    return (

        <Box borderRadius={9999}
            bgcolor={'lightgray'} display={'flex'}
            flexDirection={'row'} alignItems={'center'}>

            <img src={images[type]}
                width={styles?.allergenIconSize ?? 1}
                height={styles?.allergenIconSize ?? 1}
                alt={type}
            />

            <Box
                px={1}
                fontSize={styles?.allergenFontSize}
                fontWeight={'bold'}
                color={'darkred'}>
                {type.toUpperCase()}
            </Box>

        </Box>

    );

}
