import { useEffect, useState } from 'react';
import { normaliseError } from '../config/utils';

export type TManagedData = {
    user?: string;
    password?: string;
};


/**
 * Get managed data from navigator.managed
 *
 * undefined = not yet loaded
 * null = no managed data
 */
const useManagedData = (): TManagedData | undefined | null => {

    const [managedData, setManagedData] = useState<TManagedData | undefined | null>();

    // Handle navigator.managed callbacks
    useEffect(() => {

        updateManagedData().catch(handleError);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        navigator.managed.addEventListener('managedconfigurationchange', () => {
            updateManagedData().catch(handleError);
        });


        function updateManagedData(): Promise<void> {

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            const managed = navigator.managed;

            if (managed === undefined) {
                setManagedData({});
                return Promise.resolve();
            }

            const props = ['user', 'password'];

            const managedConfiguration = managed.getManagedConfiguration ?
                managed.getManagedConfiguration(props) :
                Promise.reject('getManagedConfiguration is not a function');

            return managedConfiguration.then((data: TManagedData) => {
                setManagedData(data);
            });

        }


    }, []);

    function handleError(error: unknown) {
        console.error(normaliseError(error).message);
        setManagedData(null);
    }

    return managedData;

};

export default useManagedData;
