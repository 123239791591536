/**
 * Is app running on localhost
 */
export const isLocalhost = (): Boolean => {


    return window.location.hostname === 'localhost' ||

        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||

        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) !== null;

};

/**
 * Makes errors typesafe by normalising to the Error object
 * @param err
 * @returns
 */
export function normaliseError(err: unknown): Error {

    if (err instanceof Error) return err;

    if (typeof err === 'string') return new Error(err);

    if (typeof err === 'number') return new Error(err.toString());

    if (typeof err === 'object') return new Error(JSON.stringify(err));

    // Catch all, not ideal
    // TODO: Handle onCall errors from firebase
    return new Error(JSON.stringify(err));

}
