/**
 * wakeLock for kiosk
 *
 * In kiosk mode on Chrome OS the screen switches off immediately, call wakeLock to prevent this
 *
 */

import { normaliseError } from './utils';

/**
 * Initialise chrome kiosk config
 */
export default async function init(): Promise<void> {

    console.debug('Wakelock init');

    try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await navigator.wakeLock.request();
    } catch (error) {
        console.error(`weakeLock:ERROR:${normaliseError(error).message}`);
    }

}
